<template>
  <div class="cpi-presentation">
    <div class="p-12 presentation-header">
      <div class="container d-flex justify-space-between text-center ">
        <h2 class="white--text">{{ $t('cpiOpportunityList.title') }}</h2>
        <img src="../../assets/aon_logo_white.svg" alt="Aon" width="68" />
      </div>
    </div>
    <template v-for="opp in selectedOpportunities">
      <div
        :key="opp.id"
        class="d-flex justify-space-between opportunity-card elevation-1 mb-4"
      >
        <div style="flex: 1; padding: 16px;">
          <div class="small mb-1" style="font-size: 14px;">
            <v-chip
              v-if="opp.result"
              class="left-chip py-4"
              :color="`${getStatusColor(opp)}-background`"
              :text-color="`${getStatusColor(opp)}-dark`"
            >
              {{ $t(`cpiOpportunityList.result.${opp.result}`) }}
            </v-chip>
          </div>
          <div class="h4 mb-2" style="font-weight: 600;">
            {{ opp.borrowerName }}
          </div>
          <div class="d-flex mb-4">
            <div
              class="d-flex align-center"
              style="margin-right: 16px; font-weight: 500;"
            >
              <i class="fad fa-search-dollar mr-1"></i>
              {{ opp.creditAnalysis }}
            </div>
            <div class="d-flex align-center" style="font-weight: 500;">
              <i class="fad fa-file-certificate mr-1"></i>
              {{ opp.patentAnalysis }}
            </div>
          </div>
          <div class="d-flex card-details">
            <div style="margin-right: 16px;">
              <span
                style="font-size: 14px; display: block;"
                class="aon-gray-03--text"
              >
                Lender
              </span>
              <span>{{ opp.lenderName }}</span>
            </div>
            <div style="margin-right: 16px;">
              <span
                style="font-size: 14px; display: block;"
                class="aon-gray-03--text"
              >
                Loan Amt.
              </span>
              <span>{{ getLoanAmount(opp) }}</span>
            </div>
            <div style="margin-right: 16px;">
              <span
                style="font-size: 14px; display: block;"
                class="aon-gray-03--text"
              >
                Target Close
              </span>
              <span>{{ opp.targetCloseDate }}</span>
            </div>
            <div>
              <span
                style="font-size: 14px;display: block;"
                class="aon-gray-03--text"
              >
                Valuation Priority
              </span>
              <span>{{ opp.valuationPriority }}</span>
            </div>
            <!-- <div>
              <span
                style="font-size: 14px;display: block;"
                class="aon-gray-03--text"
              >
                Revenue
              </span>
              <span>$32M</span>
            </div> -->
          </div>
        </div>
        <div
          class="dt-flex next-steps-content ml-4 pa-4"
          style="background: #f3f8f8 !important;border-left: 1px solid #CDDBDE;flex: 1;"
        >
          <p class="mb-2 small aon-gray-03--text">Next Steps</p>
          <p>
            <v-textarea :value="opp.nextSteps" rows="5" outlined readonly />
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CpiPresentation',
  data() {
    return {
      cpiOpportunityListHeaders: [
        {
          sortable: false,
          value: 'borrowerName',
          text: this.$t('cpiOpportunityList.headers.borrowerName'),
          width: '10%',
        },
        {
          sortable: false,
          value: 'lenderName',
          text: this.$t('cpiOpportunityList.headers.lenderName'),
          width: '8%',
        },
        {
          sortable: false,
          value: 'targetCloseDate',
          text: this.$t('cpiOpportunityList.headers.targetCloseDate'),
          width: '8%',
        },
        {
          sortable: false,
          value: 'result',
          text: this.$t('cpiOpportunityList.headers.result'),
          width: '8%',
        },
        {
          sortable: false,
          value: 'loanAmount',
          text: this.$t('cpiOpportunityList.headers.loanAmount'),
          width: '9%',
        },
        {
          sortable: false,
          value: 'valuationPriority',
          text: this.$t('cpiOpportunityList.headers.valuationPriority'),
        },
        {
          sortable: false,
          value: 'creditAnalysis',
          text: this.$t('cpiOpportunityList.headers.creditAnalysis'),
        },
        {
          sortable: false,
          value: 'patentAnalysis',
          text: this.$t('cpiOpportunityList.headers.patentAnalysis'),
        },
      ],
    };
  },
  computed: {
    ...mapState('cpiOpportunities', {
      selectedOpportunities: state => state.selected,
    }),
  },
  methods: {
    getStatusColor(opportunity) {
      return opportunity.status === 'open' ? 'success' : 'info';
    },
    getLoanAmount(item) {
      let display = '';
      if (item.minLoanAmount && !item.maxLoanAmount) {
        display = '';
      } else if (item.minLoanAmount && item.maxLoanAmount) {
        display = `${item.minLoanAmount}M - ${item.maxLoanAmount}M`;
      } else if (item.maxLoanAmount) {
        display = `${item.maxLoanAmount}M` || '';
      }
      return display;
    },
  },
};
</script>

<style lang="scss">
.cpi-presentation {
  .opportunity-card {
    border-radius: $sheet-border-radius;
    background-color: $white;
    border: $border-gray-05;
    border-left: 4px solid $success;
  }

  .header-nav {
    display: none !important;
  }

  .presentation-header {
    position: fixed;
    background: $aon-peacock;
    top: 0;
    width: 100vw;
    z-index: 10;
    left: 0;
  }
}

.header-nav {
  display: none !important;
}

.presentation-header {
  position: fixed;
  background: $aon-peacock;
  top: 0;
  width: 100vw;
  z-index: 10;
  left: 0;
}
</style>
